// @ts-strict-ignore
import { DropdownItem } from 'reactstrap';
import { useDispatch, useSelector } from 'src/store/store';
import ButtonDropdown from 'src/components/buttons/buttonDropdown';
import { fetchPosts } from 'src/modules/community/communityHome/actions';
import i18n from 'src/utils/translate';
const options = {
  'latest': i18n.t('Latest posts'),
  'popular': i18n.t('Popular posts')
};
type Props = {
  filter: string;
};
export default function PostFilterDropdown({
  filter
}: Props) {
  const dispatch = useDispatch();
  const communitySlug = useSelector(({
    context
  }) => context.currentCommunity?.slug);
  const onChange = filter => dispatch(fetchPosts({
    communitySlug,
    filter
  }));
  return <ButtonDropdown color="none" text={options[filter]} data-sentry-element="ButtonDropdown" data-sentry-component="PostFilterDropdown" data-sentry-source-file="postFilterDropdown.tsx">
      <DropdownItem onClick={() => onChange('latest')} data-sentry-element="DropdownItem" data-sentry-source-file="postFilterDropdown.tsx">
        {options['latest']}
      </DropdownItem>
      <DropdownItem onClick={() => onChange('popular')} data-sentry-element="DropdownItem" data-sentry-source-file="postFilterDropdown.tsx">
        {options['popular']}
      </DropdownItem>
    </ButtonDropdown>;
}