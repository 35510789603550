// @ts-strict-ignore
import { getPublic } from 'src/utils/api';

export const validateTag = async (tagName: string) => {
  try {
    const res = await getPublic(`tag/${tagName}`, { maxRedirects: 0 });
    if (res.status === 200 && res.data.concept) {
      const conceptNHSMap: { [key: string]: string } = {
        'CONDITION': 'conditions',
        'TREATMENT': 'medicines',
      };
      const tagType = conceptNHSMap[res.data.concept];
      return { success: true, tagType, longName: res.data.longName };
    }
  } catch (ex) {
    if (ex.response?.status === 301) {
      return { success: false, redirect: true, location: ex.response.headers.location };
    }
    return { success: false, status: ex.response?.status || 500 };
  }
  return { success: false, status: 500 };
};
