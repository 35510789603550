import { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Featured } from 'src/constants/types';
import { formatLink } from 'src/utils/featuredPosts';
import i18n from 'src/utils/translate';
import { ResultsPost, ResultsPostTitle, ResultsPostBody } from 'styles-js/resultsList';
import { LinkButton, SecondaryButton } from 'styles-js/buttons';
type Props = {
  featuredPost: Featured;
  onClick: (e: SyntheticEvent) => void;
  onDismiss: () => void;
};
export default function NormalFeaturedPost({
  featuredPost,
  onClick,
  onDismiss
}: Props) {
  const formattedLink = formatLink(featuredPost.callToActionUrl);
  return <ResultsPost data-sentry-element="ResultsPost" data-sentry-component="NormalFeaturedPost" data-sentry-source-file="normalFeaturedPost.tsx">
      {featuredPost.imageUrl && <a href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={onClick}>
          <StyledImage alt={featuredPost.title} loading="lazy" src={featuredPost.imageUrl} />
        </a>}
      <PostTitle data-sentry-element="PostTitle" data-sentry-source-file="normalFeaturedPost.tsx">
        <a href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={onClick}>
          {featuredPost.title}
        </a>
        <LinkButton onClick={onDismiss} data-sentry-element="LinkButton" data-sentry-source-file="normalFeaturedPost.tsx">{i18n.t('Hide')}</LinkButton>
      </PostTitle>
      <ResultsPostBody data-sentry-element="ResultsPostBody" data-sentry-source-file="normalFeaturedPost.tsx">
        {featuredPost.snippet}
      </ResultsPostBody>
      <div>
        <SecondaryButton as="a" href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={onClick} data-sentry-element="SecondaryButton" data-sentry-source-file="normalFeaturedPost.tsx">
          <Cta data-sentry-element="Cta" data-sentry-source-file="normalFeaturedPost.tsx">
            {featuredPost.callToActionText}
          </Cta>
        </SecondaryButton>
      </div>
      <Footer data-sentry-element="Footer" data-sentry-source-file="normalFeaturedPost.tsx">
        <FooterContent data-sentry-element="FooterContent" data-sentry-source-file="normalFeaturedPost.tsx">
          {i18n.t('Featured by')} {featuredPost.featuredByCommunityName}
        </FooterContent>
      </Footer>
    </ResultsPost>;
}
export const PostTitle = styled(ResultsPostTitle)`
  display: flex;
  a {
    flex: 1;
    color: ${({
  theme
}) => theme.colorBlack};
    transition: all 0.5s ease;
    &:hover, &:focus, &:active {
      color: ${({
  theme
}) => theme.colorGreyDark};
    }
  }
  button {
    align-self: flex-start;
  }
`;
export const Cta = styled.span`
  padding: 10px;
  color: ${({
  theme
}) => theme.colorTextLinkPrimary};
  cursor: pointer;

  &:hover, &:focus, &:active {
    color: ${({
  theme
}) => theme.colorTextLinkPrimaryHover};
  }
`;
const StyledImage = styled.img`
  width: 100%;
  height: auto;
  padding-bottom: 15px;

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    padding-bottom: 10px;
  }
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
const FooterContent = styled.div`
  color: ${({
  theme
}) => theme.colorGreyDark};
`;