// @ts-strict-ignore
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { slugify } from 'underscore.string';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { getLocalStorageItem, Key, removeLocalStorageItem } from 'src/utils/localStorage';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import PostItemMeta from 'src/modules/shared/resultsList/components/postItemMeta';
import PostLink from 'src/modules/shared/links/postLink';
import { LinkButton } from 'styles-js/buttons';
import { titleSmall } from 'styles-js/mixins/typography';
import { Body, MetaTextWrapper, PostItem, Title } from 'styles-js/postItem';
import { PostType } from 'src/constants/types';
export default function KeepReading(): JSX.Element {
  const [mounted, setMounted] = useState(false);
  const [hideClicked, setHideClicked] = useState(false);
  const {
    query: {
      communitySlug
    }
  } = useRouter();
  const {
    currentUser
  } = useSelector(({
    context
  }) => context);
  const post: lastViewedPostSecondPostBlock = JSON.parse(getLocalStorageItem(Key.lastViewedPostSecondPostBlock) || '{}');
  useEffect(() => {
    if (post?.dateKeyCreated) {
      const today = new Date();
      const dateKeyCreated = new Date(post.dateKeyCreated);
      const TWENTY_FOUR_HOURS = 1440;
      if (differenceInMinutes(today, new Date(dateKeyCreated)) >= TWENTY_FOUR_HOURS) {
        setMounted(false);
        removeLocalStorageItem(Key.lastViewedPostSecondPostBlock);
      } else if (!hideClicked) {
        setMounted(true);
      }
    }
  }, [hideClicked, post]);
  if (!currentUser || !post.postId || communitySlug !== post.communitySlug) return null;
  const onHideClick = () => {
    trackFootfallEvent(Event.Clicked, {
      clickType: 'hide-post-joined-from',
      clickMetadata: {
        section: Section.CommunityHome
      }
    });
    setHideClicked(true);
    setMounted(false);
    removeLocalStorageItem(Key.lastViewedPostSecondPostBlock);
  };
  return mounted && <Container>
      <HeaderWrapper>
        <HeaderTitle>{i18n.t(`Haven't finished reading?`)}</HeaderTitle>
        <LinkButton onClick={onHideClick}>{i18n.t('Hide')}</LinkButton>
      </HeaderWrapper>
      <BodyWrapper>
        <PostItem>
          <PostLink communitySlug={post.communitySlug} isPrivate={post.isPrivate} postId={post.postId} postTypeId={PostType.Post} urlEncodedTitle={slugify(post.title)} onClick={() => {
          trackFootfallEvent(Event.Clicked, {
            clickType: 'post-title',
            clickMetadata: {
              section: Section.CommunityHome
            }
          });
        }}>
            <Title>{post.title}</Title>
          </PostLink>
          <PostLink communitySlug={post.communitySlug} isPrivate={post.isPrivate} postId={post.postId} postTypeId={PostType.Post} urlEncodedTitle={slugify(post.title)} onClick={() => {
          trackFootfallEvent(Event.Clicked, {
            clickType: 'post-body',
            clickMetadata: {
              section: Section.CommunityHome
            }
          });
        }}>
            <Body>{post.snippet}</Body>
          </PostLink>
          <PostItemMeta authorAvatarUrl={post.authorAvatarUrl} authorBadge={post.authorBadge} authorUsername={post.authorUsername} dateCreated={post.dateCreated} onAvatarClick={() => {
          trackFootfallEvent(Event.Clicked, {
            clickType: 'post-avatar',
            clickMetadata: {
              section: Section.CommunityHome
            }
          });
        }} onUsernameClick={() => {
          trackFootfallEvent(Event.Clicked, {
            clickType: 'post-username',
            clickMetadata: {
              section: Section.CommunityHome
            }
          });
        }} />
        </PostItem>
      </BodyWrapper>
    </Container>;
}
type lastViewedPostSecondPostBlock = {
  authorAvatarUrl: string;
  authorBadge?: string;
  authorUsername?: string;
  communitySlug?: string;
  dateCreated?: string;
  dateKeyCreated: string;
  postId: number;
  snippet: string;
  title: string;
  isPrivate: boolean;
} | Record<string, never>;
const Container = styled.div`
  margin-top: 25px;
  border-bottom: ${({
  theme
}) => theme.greyLine};
  @media (${({
  theme
}) => theme.overScreenSmall}) {
    margin: 0 0 30px;
    border-bottom: none;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  @media (${({
  theme
}) => theme.overScreenSmall}) {
    padding: 0 0 10px;
  }
`;
const HeaderTitle = styled.h2`
  ${titleSmall};
  margin: 0;
  color: ${({
  theme
}) => theme.colorTextLight};
`;
const BodyWrapper = styled.div`
  @media (${({
  theme
}) => theme.overScreenSmall}) {
    padding: 0 20px;
    border: ${({
  theme
}) => theme.greyLine};
  }

  ${MetaTextWrapper} a {
    margin-right: 8px;
    color: ${({
  theme
}) => theme.colorGreyDark};
    font-weight: ${props => props.theme.fontWeightNormal};
    transition: all 0.5s ease;
    &:hover, &:focus, &:active {
      color: ${({
  theme
}) => theme.colorBlack};
    }
  }
`;