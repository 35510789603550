import { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { Featured } from 'src/constants/types';
import { formatLink } from 'src/utils/featuredPosts';
import i18n from 'src/utils/translate';
import { Cta, PostTitle } from 'src/modules/community/shared/components/featuredPosts/normalFeaturedPost';
import { SecondaryButton } from 'styles-js/buttons';
type Props = {
  featuredPost: Featured;
  onClick: (e: SyntheticEvent) => void;
  onDismiss: () => void;
};
export default function StickyFeaturedPost({
  featuredPost,
  onDismiss,
  onClick
}: Props) {
  const formattedLink = formatLink(featuredPost.callToActionUrl);
  const handleClick = (e: SyntheticEvent) => {
    trackFootfallEvent(Event.Clicked, {
      clickType: 'featured-post',
      clickMetadata: {
        section: Section.StickyFeaturedPostFooter
      }
    });
    onClick(e);
  };
  return <Container data-sentry-element="Container" data-sentry-component="StickyFeaturedPost" data-sentry-source-file="stickyFeaturedPost.tsx">
      <ContainerInner data-sentry-element="ContainerInner" data-sentry-source-file="stickyFeaturedPost.tsx">
        <PostTitle data-sentry-element="PostTitle" data-sentry-source-file="stickyFeaturedPost.tsx">
          <a href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={e => handleClick(e)}>
            {featuredPost.title}
          </a>
          <LinkButton onClick={onDismiss} data-sentry-element="LinkButton" data-sentry-source-file="stickyFeaturedPost.tsx">{i18n.t('Hide')}</LinkButton>
        </PostTitle>
        <SecondaryButton as="a" href={formattedLink} rel="nofollow ugc noreferrer" target="_blank" onClick={e => handleClick(e)} data-sentry-element="SecondaryButton" data-sentry-source-file="stickyFeaturedPost.tsx">
          <Cta data-sentry-element="Cta" data-sentry-source-file="stickyFeaturedPost.tsx">
            {featuredPost.callToActionText}
          </Cta>
        </SecondaryButton>
      </ContainerInner>
    </Container>;
}
const Container = styled.div`
  background-color: ${props => props.theme.colorWhite};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: ${props => props.theme.shadowTop};
  z-index: 1050;
`;
const ContainerInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;

  @media (${props => props.theme.underScreenSmall}) {
    padding: 15px;
  }
`;
const LinkButton = styled.button`
  border: 0;
  padding: 0 0 14px 14px;
  appearance: none;
  appearance: none;
  background-color: transparent;
  font-size: ${props => props.theme.fontSizeS};
  color: ${props => props.theme.colorGreyDark};
`;