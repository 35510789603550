import Link from 'next/link';
import i18n from 'src/utils/translate';
import Avatar, { Size } from 'src/components/avatar';
import RelativeTime from 'src/components/relativeTime';
import Badge from 'src/components/badge';
import { AvatarLink, MetaWrapper, MetaTextWrapper, PopularBadge } from 'styles-js/postItem';
type Props = {
  authorAvatarUrl?: string;
  authorBadge?: string;
  authorUsername?: string;
  community?: {
    name: string;
    slug: string;
  };
  dateCreated?: string;
  hasPopularBadge?: boolean;
  onAvatarClick?: () => void;
  onCommunityClick?: () => void;
  onUsernameClick?: () => void;
};
export default function PostItemMeta({
  authorAvatarUrl,
  authorBadge,
  authorUsername,
  community,
  dateCreated,
  hasPopularBadge = false,
  onAvatarClick,
  onCommunityClick,
  onUsernameClick
}: Props) {
  const userUrl = `/user/${authorUsername}`;
  const userUrlHref = `/user/profile?username=${authorUsername}`;
  return <MetaWrapper data-sentry-element="MetaWrapper" data-sentry-component="PostItemMeta" data-sentry-source-file="postItemMeta.tsx">
      {authorAvatarUrl && authorUsername && <Link legacyBehavior passHref as={userUrl} href={userUrlHref}>
          <AvatarLink onClick={onAvatarClick}>
            <Avatar name={authorUsername} size={Size.Small} url={authorAvatarUrl} />
          </AvatarLink>
        </Link>}
      <MetaTextWrapper data-sentry-element="MetaTextWrapper" data-sentry-source-file="postItemMeta.tsx">
        {authorUsername && <Link as={userUrl} href={userUrlHref} onClick={onUsernameClick}>
            {authorUsername}
          </Link>}
        {' '}
        {authorBadge && <Badge>{authorBadge}</Badge>}
        {' '}
        {community && <>
            {i18n.t('in ')}
            <Link href={`/${community.slug}`} onClick={onCommunityClick}>
              {community.name}
            </Link>
          </>}
        {' '}
        {dateCreated && <RelativeTime rawTime={dateCreated} />}
        {hasPopularBadge && <PopularBadge>Popular</PopularBadge>}
      </MetaTextWrapper>
    </MetaWrapper>;
}