// @ts-strict-ignore
import { useSelector } from 'src/store/store';
import { useFetchFeaturedPosts, useFetchPosts, useFootfall } from 'src/modules/community/communityHome/hooks';
import { CommunityLayout } from 'src/components/layouts';
import CommunityHomeContent from 'src/modules/community/communityHome/components/communityHomeContent';
import CommunityHomeWidgets from 'src/modules/community/communityHome/components/widgets/communityHomeWidgets';
import { getCommunitySeo } from 'src/utils/seo';
import { GoogleAds } from 'src/modules/shared/modal/components/googleAdsModal';
export default function CommunityHomePage() {
  const {
    currentCommunity
  } = useSelector(({
    context
  }) => context);
  useFetchPosts();
  useFetchFeaturedPosts();
  useFootfall();
  return <CommunityLayout currentPage="home" seo={getCommunitySeo(currentCommunity)} sideBarContent={<CommunityHomeWidgets />} data-sentry-element="CommunityLayout" data-sentry-component="CommunityHomePage" data-sentry-source-file="communityHomePage.tsx">
      <CommunityHomeContent data-sentry-element="CommunityHomeContent" data-sentry-source-file="communityHomePage.tsx" />
      <GoogleAds pageType="index" data-sentry-element="GoogleAds" data-sentry-source-file="communityHomePage.tsx" />
    </CommunityLayout>;
}