// @ts-strict-ignore
import { Fragment } from 'react';
import { stringToKey } from 'src/utils/string';
import config from 'config/config';
const smileysMap = new Map([[':)', 'smile.gif'], [':-)', 'smile.gif'], [';)', 'wink.gif'], [';-)', 'wink.gif'], [':d', 'bigsmile.gif'], [':-d', 'bigsmile.gif'], [':(', 'sad.gif'], [':-(', 'sad.gif'], [':x', 'angry.gif'], [':|', 'confuse.gif'], [':-/', 'confuse.gif'], [':p', 'funny.gif'], [':-p', 'funny.gif'], [':o', 'surprise.gif'], [':-o', 'surprise.gif'], ['8-)', 'cool.gif'], [':\'(', 'cry.png']]);

// Returns smiley image
export const getSmiley = ({
  originalText
}, editable = false): JSX.Element => {
  const smileyChars = originalText.toLowerCase();
  if (editable || !smileysMap.has(smileyChars)) {
    return <Fragment key={stringToKey(originalText)}>{originalText}</Fragment>;
  }
  const fileName = smileysMap.get(smileyChars);
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img alt={originalText} key={stringToKey(originalText)} src={config.cdn.smiley + fileName} title={originalText} data-sentry-component="getSmiley" data-sentry-source-file="getSmiley.tsx" />
  );
};