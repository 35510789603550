import { Fragment, ReactNode } from 'react';
import { stringToKey } from 'src/utils/string';

// Return styled text
type Style = {
  u: boolean;
  i: boolean;
  b: boolean;
};
export const getText = ({
  text,
  style
}: {
  text: string;
  style: Style;
}, editable = false): JSX.Element => {
  let render: ReactNode = text;
  if (style.u) {
    render = <u>{render}</u>;
  }
  if (style.i) {
    render = editable ? <i>{render}</i> : <em>{render}</em>;
  }
  if (style.b) {
    render = editable ? <b>{render}</b> : <strong>{render}</strong>;
  }
  return <Fragment key={stringToKey(text)}>{render}</Fragment>;
};