import Link from 'next/link';
import { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import { showSignupModal } from 'src/modules/shared/modal/actions';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Element, Section } from 'src/constants/footfall';
import Avatar, { Size } from 'src/components/avatar';
import CommunityLink from 'src/modules/shared/links/communityLink';
import RelativeTime from 'src/components/relativeTime';
import Badge from 'src/components/badge';
import PostItemReplies from 'src/modules/shared/resultsList/components/postItemReplies';
import Separator from 'src/components/separator';
import { ResultsPostFooter, ResultsPostFooterRow, ResultsPostFooterLinkWrapper } from 'styles-js/resultsList';
import { Community, CommunityListPost, ElasticSearchPost } from 'src/constants/types';
type Props = {
  community: Community | ElasticSearchPost['community'];
  hideReplies: boolean;
  isSearchPost: boolean;
  isCommunitySearch: boolean;
  onPostClick: (type: string) => void;
  post: CommunityListPost | ElasticSearchPost;
};
export default function PostItemFooter({
  community,
  hideReplies,
  isCommunitySearch,
  isSearchPost,
  onPostClick,
  post
}: Props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(({
    context
  }) => context.currentUser);
  const onClickUser = (e: SyntheticEvent) => {
    if (!currentUser) e.preventDefault();
    onPostClick('user');
    if (!currentUser) {
      trackFootfallEvent(Event.Clicked, {
        clickType: 'sign-up',
        clickMetadata: {
          element: Element.UserLink,
          section: Section.CommunityHomePost
        }
      });
      dispatch(showSignupModal());
    }
  };
  return <ResultsPostFooter data-sentry-element="ResultsPostFooter" data-sentry-component="PostItemFooter" data-sentry-source-file="postItemFooter.tsx">
      <Link aria-label={`View ${post.author.username} profile`} as={`/user/${post.author.username}`} href={`/user/profile?username=${post.author.username}`} onClick={e => onClickUser(e)} data-sentry-element="Link" data-sentry-source-file="postItemFooter.tsx">
        <Avatar hash={post.author.userImageHash} name={post.author.username} size={Size.Small} data-sentry-element="Avatar" data-sentry-source-file="postItemFooter.tsx" />
      </Link>
      <div>
        <ResultsPostFooterRow data-sentry-element="ResultsPostFooterRow" data-sentry-source-file="postItemFooter.tsx">
          <ResultsPostFooterLinkWrapper data-sentry-element="ResultsPostFooterLinkWrapper" data-sentry-source-file="postItemFooter.tsx">
            <Link as={`/user/${post.author.username}`} href={`/user/profile?username=${post.author.username}`} onClick={onClickUser} data-sentry-element="Link" data-sentry-source-file="postItemFooter.tsx">
              {post.author.username}
            </Link>
          </ResultsPostFooterLinkWrapper>
          {post.author.roles?.length > 0 && <Badge>{post.author.roles[0]}</Badge>}
          {isSearchPost ? !isCommunitySearch && <CommunitySearch>
                {i18n.t('in ')}
                <CommunityLink name={community.name} slug={community.slug} onClick={() => onPostClick('community')} />
              </CommunitySearch> : <>
                <Separator className="hidden-xxs" />
                <RelativeTime rawTime={post.dateCreated} />
              </>}
        </ResultsPostFooterRow>
        <ResultsPostFooterRow data-sentry-element="ResultsPostFooterRow" data-sentry-source-file="postItemFooter.tsx">
          {isSearchPost ? <>
              <RelativeTime rawTime={post.dateCreated} />
              {!hideReplies && <>
                  <Separator className="hidden-xxs" />
                  <PostItemReplies community={community} post={post} onPostClick={onPostClick} />
                </>}
            </> : <PostItemReplies community={community} post={post} onPostClick={onPostClick} />}
        </ResultsPostFooterRow>
      </div>
    </ResultsPostFooter>;
}
const CommunitySearch = styled.span`
  margin-left: 8px;
  a {
    color: ${({
  theme
}) => theme.colorGreyDark};
    transition: all 0.5s ease;
    &:hover, &:focus, &:active {
      color: ${({
  theme
}) => theme.colorBlack};
    }
  }
`;