// @ts-strict-ignore
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import { Close, MultiScreenModal } from 'src/components/modals/modal';
import { AD_ID_1, AD_ID_2, IS_GOOGLE_ADS_DISABLED, handleRouteChange, setupGPT } from 'src/utils/adverts';
import { showGoogleAdsModal } from 'src/modules/shared/modal/actions';
import { isUserBot } from 'src/utils/seo';

// Google Ad Manager
export default function GoogleAdsModal(): JSX.Element {
  const {
    content
  } = useSelector(({
    modal
  }) => modal.modalProps);
  return <MultiScreenModal data-sentry-element="MultiScreenModal" data-sentry-component="GoogleAdsModal" data-sentry-source-file="googleAdsModal.tsx">
      <Close showText={false} data-sentry-element="Close" data-sentry-source-file="googleAdsModal.tsx" />
      <div dangerouslySetInnerHTML={{
      __html: content
    }} />
    </MultiScreenModal>;
}
export function GoogleAds({
  pageType
}: {
  pageType: 'index' | 'posts' | 'post';
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const community = useSelector(({
    context
  }) => context.currentCommunity?.slug) ?? 'none';
  const isActive = useSelector(({
    context
  }) => !!context.currentUser);
  const post = useSelector(({
    pages
  }) => pages.singlePost?.post);
  const postId = post?.id ?? 'none';
  const tagArray = post?.entities.map(({
    slug
  }) => slug) ?? [];
  useEffect(() => {
    if (isUserBot || IS_GOOGLE_ADS_DISABLED) return;
    // Triggered by the Google Ads script
    window.parent.Backbone = {
      // parent.Backbone is a hangover from Betelgeuse
      trigger: (_, content) => dispatch(showGoogleAdsModal(content.src))
    };
    setupGPT({
      isActive,
      community,
      postId,
      tagArray,
      pageType
    });
    router.events?.on('routeChangeStart', handleRouteChange);
    return () => router.events?.off('routeChangeStart', handleRouteChange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
      <Advert id={AD_ID_1} data-sentry-element="Advert" data-sentry-source-file="googleAdsModal.tsx" />
      <Advert id={AD_ID_2} data-sentry-element="Advert" data-sentry-source-file="googleAdsModal.tsx" />
    </>;
}
const Advert = styled.div`
  width: 1;
  height: 1;
`;