// @ts-strict-ignore
import Link from 'next/link';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { combinePostsWithFeaturedPosts } from 'src/utils/featuredPosts';
import PostItem from 'src/modules/shared/resultsList/components/postItem';
import FeaturedPost from 'src/modules/community/shared/components/featuredPosts/featuredPost';
import CommunityFooterCTA from 'src/modules/community/shared/components/communityFooterCTA';
import PostListLoader from 'src/modules/shared/resultsList/components/postListLoader';
import PostFilterDropdown from 'src/modules/community/communityHome/components/postFilterDropdown';
import KeepReading from 'src/modules/community/communityHome/components/keepReading';
import Icon from 'src/components/icon';
import { MAX_POSTS_WIDGET } from 'src/constants/widgets';
import { ResultsSeeMoreCTA, ResultsList } from 'styles-js/resultsList';
export default function CommunityHomeContent(): JSX.Element {
  const {
    currentCommunity
  } = useSelector(({
    context
  }) => context);
  const {
    filter,
    widgets,
    isPostListLoading
  } = useSelector(({
    pages
  }) => pages.communityHome);
  const combinedPosts = useCombinedPosts();
  const pinnedPostsWidget = widgets.find(({
    widgetType
  }) => widgetType === 'PinnedPosts');
  const isPinnedPostsLinkEnabled = pinnedPostsWidget?.posts?.length > 0;
  return <div data-sentry-component="CommunityHomeContent" data-sentry-source-file="communityHomeContent.tsx">
      {isPinnedPostsLinkEnabled && <Link as={`/${currentCommunity.slug}/posts#pinned`} className="community__home-pinned-button" href={`/community/communityPosts?communitySlug=${currentCommunity.slug}`} onClick={() => {
      trackFootfallEvent(Event.Clicked, {
        clickType: 'pinned-posts',
        clickMetadata: {
          section: Section.MobileShortcut
        }
      });
    }}>
          <div>{i18n.t('Pinned posts')}</div>
          <Icon icon="open-right" />
        </Link>}
      <KeepReading data-sentry-element="KeepReading" data-sentry-source-file="communityHomeContent.tsx" />
      <div className="community__home-filter">
        <PostFilterDropdown filter={filter} data-sentry-element="PostFilterDropdown" data-sentry-source-file="communityHomeContent.tsx" />
      </div>
      {isPostListLoading ? <PostListLoader /> : combinedPosts.length > 0 ? <ResultsList>
            {combinedPosts.map(post => <Post key={post.featuredPostId || post.postId} post={post} />)}
            <ResultsSeeMoreCTA>
              <Link as={`/${currentCommunity.slug}/posts`} href={`/community/communityPosts?communitySlug=${currentCommunity.slug}`} onClick={() => {
          trackFootfallEvent(Event.Clicked, {
            clickType: 'see-all-posts',
            clickMetadata: {
              section: Section.CommunityHome
            }
          });
        }}>
                {i18n.t('See all posts')} <Icon icon="open-right" />
              </Link>
            </ResultsSeeMoreCTA>
          </ResultsList> : !currentCommunity.isArchived && <div className="community__home-empty">
              <span>
                {i18n.t('Nothing here yet! Be the first to ')}
                <Link as={`/${currentCommunity.slug}/posts/write`} href={`/community/write?communitySlug=${currentCommunity.slug}`}>
                  {i18n.t('share your experience')}
                </Link>
                !
              </span>
            </div>}
      <CommunityFooterCTA data-sentry-element="CommunityFooterCTA" data-sentry-source-file="communityHomeContent.tsx" />
    </div>;
}
function Post({
  post
}) {
  const {
    currentCommunity
  } = useSelector(({
    context
  }) => context);
  if (!post) return null;
  return <>
      {post.featuredPostId ? <FeaturedPost featuredPost={post} /> : <PostItem community={currentCommunity} isSearchPost={false} post={post} />}
    </>;
}
function useCombinedPosts() {
  const {
    currentCommunity
  } = useSelector(({
    context
  }) => context);
  const {
    posts
  } = useSelector(({
    pages
  }) => pages.communityHome);
  let combinedPosts = posts;
  if (currentCommunity && currentCommunity.featuredPosts) {
    const {
      featuredPosts,
      dismissedFeaturedPostIds = []
    } = currentCommunity;
    const filteredFeaturedPosts = featuredPosts.filter(({
      featuredPostId
    }) => !dismissedFeaturedPostIds.includes(featuredPostId));
    combinedPosts = combinePostsWithFeaturedPosts(posts.slice(0, MAX_POSTS_WIDGET), filteredFeaturedPosts);
  }
  return combinedPosts;
}