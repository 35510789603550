import { Fragment } from 'react';
import { slugify, truncate } from 'underscore.string';
import { stripBBTags } from 'src/utils/string';
import PostLink from 'src/modules/shared/links/postLink';
import PostItemFooter from 'src/modules/shared/resultsList/components/postItemFooter';
import { ResultsPost, ResultsPostTitle, ResultsPostBody } from 'styles-js/resultsList';
import { Community, CommunityListPost, ElasticSearchPost, PostType } from 'src/constants/types';
type Props = {
  community: Community | ElasticSearchPost['community'] | null;
  hideReplies?: boolean;
  isCommunitySearch?: boolean;
  isSearchPost?: boolean;
  onClick?: (type: string, postId: number) => void;
  post: CommunityListPost | ElasticSearchPost;
};
export default function PostItem({
  community,
  hideReplies = false,
  isCommunitySearch = false,
  isSearchPost = true,
  onClick = () => {},
  post
}: Props) {
  if (!post || !community) return null;
  const onPostClick = (type: string) => {
    onClick(type, post.postId);
  };
  let snippet;
  let mobileSnippet;
  // post is a union type so we have to narrow potential types (Property 'highlight' does not exist on type 'CommunityListPost')
  if ('highlight' in post && post.highlight) {
    snippet = highlightSearchText(post.highlight[0]);
    mobileSnippet = snippet;
  } else if ('bodySnippet' in post) {
    snippet = stripBBTags(post.bodySnippet);
    mobileSnippet = truncate(snippet, 80);
  }
  return <ResultsPost key={post.postId} data-sentry-element="ResultsPost" data-sentry-component="PostItem" data-sentry-source-file="postItem.tsx">
      <PostLink communitySlug={community?.slug} isPrivate={(post as CommunityListPost)?.isPrivate || false} postId={post.postId} postTypeId={PostType.Post} urlEncodedTitle={slugify(post.title)} onClick={() => onPostClick('post')} data-sentry-element="PostLink" data-sentry-source-file="postItem.tsx">
        <ResultsPostTitle data-sentry-element="ResultsPostTitle" data-sentry-source-file="postItem.tsx">{post.title}</ResultsPostTitle>
        <ResultsPostBody className="hidden-xs" data-sentry-element="ResultsPostBody" data-sentry-source-file="postItem.tsx">{snippet}</ResultsPostBody>
        <ResultsPostBody className="visible-xs" data-sentry-element="ResultsPostBody" data-sentry-source-file="postItem.tsx">{mobileSnippet}</ResultsPostBody>
      </PostLink>
      <PostItemFooter community={community} hideReplies={hideReplies} isCommunitySearch={isCommunitySearch} isSearchPost={isSearchPost} post={post} onPostClick={onPostClick} data-sentry-element="PostItemFooter" data-sentry-source-file="postItem.tsx" />
    </ResultsPost>;
}
/* eslint-disable react/no-array-index-key */
const highlightSearchText = (snippet: string) => {
  if (!snippet) return null;
  return snippet.split('[/b]').map((item, i) => {
    if (item.includes('[b]')) {
      const boldArray = item.split('[b]');
      return <><Fragment key={`highlight-${i}`}>{boldArray[0]}</Fragment><b key={`highlight-bold-${i}`}>{boldArray[1]}</b></>;
    } else {
      return <Fragment key={`not-highlight-${i}`}>{item}</Fragment>;
    }
  });
};